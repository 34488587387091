import { useRouter } from 'next/router';
import type { VFC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import useJwt from '@hooks/useJwt';
import useWeb3 from '@lib/web3/hooks/useWeb3';

/**
 * Controls the Web3 automation of the application, such as:
 * - Auto-connect the web3 provider (MetaMask);
 * - Suggest changing the chain if not using Avalanche.
 */
const Web3Runtime: VFC = () => {
  const router = useRouter();
  const logged = useJwt();
  const { active, activate, isChainSupported, changeNetwork } = useWeb3();
  const [changedChainOnce, setChangedChainOnce] = useState(false);
  const [activatedOnce, setActivatedOnce] = useState(false);

  const setup = useCallback(async () => {
    if (typeof window.ethereum === 'undefined' || !logged) return;

    if (!changedChainOnce && active && !isChainSupported) {
      setChangedChainOnce(true);
      await changeNetwork().catch();
    }

    if (!activatedOnce && !active) {
      setActivatedOnce(true);
      await activate().catch();
    }
  }, [activate, activatedOnce, active, changeNetwork, changedChainOnce, isChainSupported, logged]);

  useEffect(() => {
    if (router.pathname.startsWith('/auth/')) {
      return;
    }

    void setup();
  }, [router, setup]);

  return null;
};

export default Web3Runtime;
