import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { IconContext } from 'react-icons';
import type { ToastContainerProps } from 'react-toastify';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import AnalyticsRuntime from '@components/analystics/AnalyticsRuntime';
import Web3Runtime from '@components/web3/Web3Runtime';
import client from '@graphql/client';
import LockProvider from '@lib/contexts/LockProvider';
import ModalProvider from '@lib/contexts/ModalProvider';
import { getLibrary } from '@lib/web3/utils/getLibrary';
import CssBaseline from '@mui/material/CssBaseline';
import { frFR, trTR } from '@mui/material/locale';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import initialTheme, { themeOptions } from '@styles/theme';
import { isPlatformCypress } from '@utils/platform';
import { Web3ReactProvider } from '@web3-react/core';

const ToastContainer = dynamic<ToastContainerProps>(() => import('react-toastify').then((mod) => mod.ToastContainer), {
  ssr: false,
});

const VeriffProvider = dynamic(() => import('@lib/contexts/VeriffProvider'), { ssr: false });

const mappings: Record<string, any> = {
  fr: frFR,
  tr: trTR,
};

const Runtime: FC = ({ children }) => {
  const router = useRouter();
  const [theme, setTheme] = useState(initialTheme);

  useEffect(() => {
    if (!router.locale || router.locale === 'en' || !(router.locale in mappings)) {
      setTheme(initialTheme);
    } else {
      setTheme(createTheme(themeOptions, mappings[router.locale]));
    }
  }, [router.locale]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <Web3ReactProvider getLibrary={getLibrary}>
            <ApolloProvider client={client}>
              <LockProvider>
                <ModalProvider>
                  <VeriffProvider>
                    <IconContext.Provider value={{ style: { display: 'inline', verticalAlign: 'middle' } }}>
                      <CssBaseline />
                      <ToastContainer autoClose={isPlatformCypress() ? 1000 : 5000} pauseOnFocusLoss={false} />
                      <Web3Runtime />
                      <AnalyticsRuntime />
                      {children}
                    </IconContext.Provider>
                  </VeriffProvider>
                </ModalProvider>
              </LockProvider>
            </ApolloProvider>
          </Web3ReactProvider>
        </StyledEngineProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default Runtime;
