import Script from 'next/script';
import { useEffect } from 'react';
import type { VFC } from 'react';
import useGTM from '@hooks/useGTM';
import useWeb3 from '@lib/web3/hooks/useWeb3';

const AnalyticsRuntime: VFC = () => {
  const { active, account } = useWeb3();
  const send = useGTM();

  useEffect(() => {
    send({ metamask_connected: active && !!account });
  }, [active, account, send]);

  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtm.js?id=${process.env.NEXT_PUBLIC_GTM_ID}`}
        onLoad={() => {
          send({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        }}
      />

      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_ID}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    </>
  );
};

export default AnalyticsRuntime;
