import classnames from 'classnames';
import { BsCheckLg } from 'react-icons/bs';
// import type { TailwindValuesColor } from 'tailwindcss/tailwind-config';
import '@mui/lab/themeAugmentation';
import type { StepIconProps } from '@mui/material/StepIcon';
import type { PaletteColorOptions, SimplePaletteColorOptions, ThemeOptions } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import tailwind from '@styles/tailwind';

type TailwindValuesColor = {
  primary: string;
  secondary: string;
  neutral: string;
  background: string;
};

declare module '@mui/material/styles' {
  export interface Palette {
    neutral: SimplePaletteColorOptions;
    highlight: SimplePaletteColorOptions;
    contrast: SimplePaletteColorOptions;
  }

  // allow configuration using `createTheme`
  export interface PaletteOptions {
    neutral?: PaletteColorOptions;
    highlight?: PaletteColorOptions;
    contrast?: PaletteColorOptions;
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
    highlight: true;
    contrast: true;
  }
}

const colors = tailwind?.theme?.colors as TailwindValuesColor;

export const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: 'Montserrat, serif',
    h1: {
      fontSize: '2.5rem',
      margin: '16px 0 16px 0',
    },
    h2: {
      fontSize: '1.8rem',
      margin: '12px 0 12px 0',
    },
  },
  components: {
    MuiAlert: {
      defaultProps: {
        severity: 'error',
        variant: 'outlined',
        style: {
          padding: '1rem',
          marginBottom: '1rem',
          alignItems: 'center',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        color: 'primary',
        variant: 'contained',
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        color: 'primary',
        variant: 'contained',
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        classes: {
          root: 'bg-black bg-opacity-30',
        },
      },
    },
    MuiStepLabel: {
      defaultProps: {
        classes: {
          label: 'font-black text-lg',
        },
        StepIconComponent: (props: StepIconProps) => {
          return (
            <div
              className={classnames(
                'flex justify-center items-center h-min w-6 h-6 font-black',
                props.completed || props.active ? 'bg-primary' : 'bg-dark',
                {
                  'opacity-60 cursor-pointer hover:opacity-100': props.completed,
                },
              )}
            >
              {props.completed ? <BsCheckLg style={{ verticalAlign: 'middle' }} /> : props.icon}
            </div>
          );
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: 'normal',
        fullWidth: true,
        SelectProps: { native: true },
      },
      styleOverrides: {
        // @ts-expect-error Force position to be relative
        root: {
          position: 'relative !important',
        },
      },
    },
  },
  palette: {
    mode: 'dark',
    primary: { main: colors.primary },
    secondary: { main: colors.secondary },
    neutral: { main: colors.neutral },
    highlight: { main: '#5EEAD4' }, // Cyan
    contrast: { main: '#FFFFFF', contrastText: '#090E19' },
    background: {
      default: colors.background, // Background dark blue
      paper: colors.background, // Background dark blue
    },
    text: { primary: '#FFFFFF', disabled: '#9CA3AF' }, // White
  },
  shape: {
    borderRadius: 0,
  },
};

const theme = createTheme(themeOptions);

export default theme;
