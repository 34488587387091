import type { JsonRpcProvider } from '@ethersproject/providers';
import EIP1193Provider from '@lib/web3/providers/EIP1193Provider';
import type TestingProvider from '@lib/web3/providers/TestingProvider';

export function getLibrary(provider: any): JsonRpcProvider {
  if ((provider as TestingProvider).bypass) {
    return provider as TestingProvider;
  }

  return new EIP1193Provider(provider, 'any');
}
