import merge from 'lodash/merge';
import Router from 'next/router';
import { toast } from 'react-toastify';
import type { GraphQLRequest } from '@apollo/client';
import { ApolloClient, defaultDataIdFromObject, from, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import env from '@lib/app/env';
import { JWT_STORAGE_KEY } from '@lib/constants';
import { isPlatformBrowser, isPlatformServer } from '@utils/platform';

const httpLink = new HttpLink({
  uri: `${env.NEXT_PUBLIC_API_URL}/graphql`,
});

const authLink = setContext(<T>(_: GraphQLRequest, context: T) => {
  const headers: Record<string, string> = {};

  try {
    const raw = localStorage.getItem(JWT_STORAGE_KEY);

    if (raw) {
      headers.authorization = JSON.parse(raw);
    }
  } catch (e) {
    // JWT is malformed
    localStorage.removeItem(JWT_STORAGE_KEY);
    return Router.push('/auth/login');
  }

  let impersonatingId: string | null = null;

  if (isPlatformBrowser()) {
    const found = localStorage.getItem('impersonate.id');

    if (found) {
      impersonatingId = JSON.parse(found) as string;
    }
  }

  if (impersonatingId) {
    headers['x-impersonate'] = impersonatingId;
  }

  return merge(context, { headers });
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    for (const error of graphQLErrors) {
      if (error.extensions?.public) {
        toast.error(error.message);
      }

      switch (error.extensions.code) {
        case 'UNAUTHENTICATED':
          localStorage.removeItem(JWT_STORAGE_KEY);
          // User is not authenticated
          void Router.push('/auth/login');
          break;
      }
    }
  }
});

const cache = new InMemoryCache({
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      case 'UserWallet':
        if (typeof responseObject.address !== 'string') return undefined;
        return `UserWallet:${responseObject.address}`;
      default:
        return defaultDataIdFromObject(responseObject);
    }
  },
});

const client = new ApolloClient({
  ssrMode: isPlatformServer(),
  link: from([authLink, errorLink, httpLink]),
  cache,
});

export default client;
